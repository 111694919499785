import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import PartnerPageTemplate from "../../templates/partner-page"

const PartnersPageFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <PartnerPageTemplate {...props} />
    </Layout>
  )
}
export default PartnersPageFrench

export const pageQuery = graphql`
  query PartnersPageFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/partner/index.fr.md/" }
    ) {
      frontmatter {
        ...PartnerPage
      }
    }
  }
`
